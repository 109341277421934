<template>
  <div class="popup_wrap doc_req_pop" style="width:900px; height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form
      id="frm_req_pop"
      @submit.prevent
    >
      <div class="popup_cont cod_cont_area">
        <!-- popup_cont -->
        <h1 class="page_title">
          <!-- Letter of Indemnity -->
          {{ $t('msg.ONEX050P190.001') }}
          <span class="position_relative screen-to-pdf-hide">
            <button class="tbl_icon help" @mouseover="showToolTip()" @mouseleave="showToolTip()">{{ $t('msg.ONEX050P190.057') }}</button>
            <div v-show="toolTipActive" class="tooltip_wrap short position_absolute" style="width:400px; left:0; top:28px;"><!-- tooltip_wrap   -->
              <div class="cont"><!-- cont -->
                <p class="title">{{ $t('msg.ONEX050P190.001') }}</p><!-- Letter of Indemnity -->
                <ul class="bul_list_sm t2">
                  <li>
                    {{ $t('msg.ONEX050P190.002') }} <br /> {{ $t('msg.ONEX050P190.003') }}
                  </li>
                </ul>
              </div><!-- cont -->
            </div><!-- tooltip_wrap // -->
          </span>
        </h1>
        <div
          v-if="delegateYn === 'Y'"
          class="content_box"
        >
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX050P190.004') }}</th><!-- 날짜 -->
                <td>
                  <div class="tbl_form">
                    {{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.005') }}</th><!-- 수신 -->
                <td>{{ $t('msg.ONEX050P190.006') }}</td><!-- KOREA MARINE TRANSPORT CO., LTD. -->
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.007') }}</th><!-- 발신 -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.shprCstNm }}
                  </div>
                  <div class="tbl_form mt5">
                    <div>
                      <span class="dv col_1">{{ formData.reqPicNm }}</span>
                      <span class="dv col_2">{{ formData.reqPicTelNo }}</span>
                      <span class="dv col_3">{{ formData.reqPicEmlAddr }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.008') }}</th><!-- 실화주명 -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.actShprCstEnm }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div
          v-if="delegateYn === 'Y'"
          class="content_box mt10 cod_cont_area"
        >
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX050P190.009') }}</th><!-- Bl No. -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.blNo }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.010') }}</th><!-- Port of Loading/Discharge -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      {{ bookingInfo.polPlcNm }}
                    </span>
                    <span class="dv col_3">
                      {{ bookingInfo.podPlcNm }}
                    </span>
                    <span class="dv col_4">

                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.011') }}</th><!-- Vessel/Voyage -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      {{ bookingInfo.vslNm }}
                    </span>
                    <span class="dv col_3">
                      {{ bookingInfo.voyNo }}
                    </span>
                    <span class="dv col_4">

                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="tbl_row mt10">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX050P190.014') }}</th><!-- Description of goods -->
                <td class="screen-to-pdf-hide">
                  <textarea placeholder="Description of goods" v-model="description"></textarea>
                </td>
                <td class="screen-to-pdf-show">
                  <p>{{ description }}</p>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.015') }}</th><!-- Invoice Value -->
                <td class="screen-to-pdf-hide">
                  <input type="text" v-model="invoiceModel" id="invoiceValue" class="col_5" placeholder="Invoice Value">
                </td>
                <td class="screen-to-pdf-show">
                  <p>{{ invoiceModel }}</p>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.016') }}</th><!-- 3rd party Shipper -->
                <td class="screen-to-pdf-hide">
                  <input type="text" v-model="formData.updtShprCstNm" id="updtShprCstNm" class="col_5" placeholder="3rd party Shipper" @input="checkValidationInput('updtShprCstNm')">
                </td>
                <td class="screen-to-pdf-show">
                  <p>{{ formData.updtShprCstNm }}</p>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX050P190.041') }}</th><!-- 셔틀 사유 -->
                <td>
                  <div class="tbl_form screen-to-pdf-hide">
                    <span class="dv col_6">
                      <input type="text" v-model="formData.reqRsnCont" placeholder="사유 및 요청사항">
                    </span>
                    <span class="dv"></span>
                  </div>
                  <div class="tbl_form screen-to-pdf-show">
                    <p>
                      <span class="dv col_6">{{ formData.reqRsnCont }}</span>
                      <span class="dv"></span>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="normal_box">
            <ol class="mt10">
              <li>{{ $t('msg.ONEX050P190.017') }}</li>
              <li>{{ $t('msg.ONEX050P190.018') }}</li>
              <li>{{ $t('msg.ONEX050P190.019') }}</li>
              <li>{{ $t('msg.ONEX050P190.020') }}</li>
              <li>{{ $t('msg.ONEX050P190.021') }}</li>
              <li>{{ $t('msg.ONEX050P190.022') }}</li>
            </ol>
            <p class="text_center mt10">{{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}</p>
            <p class="text_center mt10">
              <span class="screen-to-pdf-hide">
                <input
                  v-model="agreeYn"
                  type="checkbox"
                  name="no"
                  id="chk1"
                  true-value="Y"
                  false-value="N"
                >
                <label for="chk1"><span class="offscreen">No</span></label>
              </span>
              <span class="screen-to-pdf-show">
                <input
                  checked="checked"
                  disabled="disabled"
                  type="checkbox"
                  name="no"
                  id="chk1_temp"
                >
                <label for="chk1_temp"><span class="offscreen">No</span></label>
              </span>
              {{ $t('msg.ONEX050P190.023') }} (<span class="ml10 mr10">{{ bookingInfo.actShprCstEnm }}</span>){{ $t('msg.ONEX050P190.024') }}
            </p>
            <p class="txt_desc mt10" style="background: none;">
              <span class="font_medium">[{{ $t('msg.ONEX050P190.025') }}]</span>
              {{ $t('msg.ONEX050P190.026') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX050P190.027') }} : {{ $t('msg.ONEX050P190.028') }}<br>
            </p>
          </div>
        </div><!-- content_box // -->

        <div
          v-if="delegateYn === 'N'"
          class="content_box"
        >
          <!-- content_box -->
          <!-- 국내/해외 문구 삭제 -->
          <!-- <p>
            {{ $t('msg.ONEX050P190.030') }}
          </p> -->
          <p class="mt5">
            <span><a class="button sm" href="#" @click.prevent="excelDownload('LOIRequest.xlsx')">{{ $t('msg.ONEX050P190.031') }}</a></span><!-- Shipper LOI 요청서 양식 다운 -->
            <span v-if="memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'I'" class="ml5"><a class="button sm" href="#" @click.prevent="moveDelegateReqPage()">{{ $t('msg.ONEX050P190.032') }}</a></span><!-- 위수임 신청 바로가기 -->
            <span v-if="memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'I'">
              <button type="button" class="tbl_icon help ml10" @click.prevent="guidePop()">{{ $t('msg.ONEX050P190.033') }}</button><!-- 도움말 -->
            </span>
          </p>

          <h2 class="content_title">{{ $t('msg.ONEX050P190.034') }}</h2><!-- Shipper LOI 요청서 제출 -->
          <table class="tbl_row">
            <colgroup>
              <col style="width:200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.ONEX050P190.035') }}</th><!-- 제출 -->
                <td>
                  <div class="filebox" id="file_att">
                    <a
                      v-if="uploadParam.length === 0"
                      class="button sm gray file"
                      @click.prevent="uploadFilePop()"
                    >
                      {{ $t('msg.ONEX050P190.036') }}
                    </a>
                    <div v-if="uploadParam.length > 0">
                      <span class="tbl_icon attach"></span>
                      <a class="text_link">{{ uploadParam[0].originalName }}</a>
                      <a
                        class="tbl_icon attdel"
                        @click="removeUploadFile()"
                      >X</a>
                    </div>
                  </div>
                </td>
                <td class="border_left_none">
                  <!--<a class="button sm" href="#">제출</a>-->
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="memberDetail.userCtrCd === 'KR'" class="txt_desc mt10" style="background: none;">
            <span class="font_medium">[{{ $t('msg.ONEX050P190.025') }}]</span>
            {{ $t('msg.ONEX050P190.026') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX050P190.027') }} : {{ $t('msg.ONEX050P190.028') }}<br>
          </p>
        </div><!-- content_box // -->

        <div class="mt10 text_right screen-to-pdf-hide">
          <a class="button blue lg mr5" @click="reqSave()">{{ $t('msg.ONEX050P190.035') }}</a>
          <a v-if="delegateYn === 'Y'" class="button gray lg" @click="$emit('close')">{{ $t('msg.ONEX050P190.040') }}</a>
          <a v-else class="button gray lg" @click="confirmClose()">{{ $t('msg.ONEX050P190.040') }}</a>
        </div>

        <win-layer-pop v-if="delegateYn === 'Y'" class="popup_dim">
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="popParams"
            @close="closePopup"
            @1="selectFunc"
          />
        </win-layer-pop>
        <win-layer-pop v-if="delegateYn === 'N'" ref="uploadPop" class="popup_dim">
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="uploadParam"
            :single-file-yn="true"
            @set-dext-file-info="arrangeUploadParam"
            @close="closePopup"
          />
        </win-layer-pop>
        <div v-show="false" id="guide_pop" v-if="delegateYn === 'N'">
          <component
            :is="customComponent"
            v-if="customComponent"
            @close="closePopup"
          />
        </div>
      </div><!-- popup_cont -->
    </form>
  </div>
</template>

<script>

import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import transCommon from '@/api/rest/trans/transCommon'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ContainerGuidePop from '@/pages/trans/popup/ContainerGuidePop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'IndemnityReqPop',
  components: {
    rootComputed,
    WinLayerPop,
    DocUploadPop,
    ContainerGuidePop
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          type: ''
        }
      }
    }
  },
  data () {
    return {
      delegateYn: 'N',
      ops: {
        scrollPanel: {
          speed: 300
        },
        bar: {
          showDelay: 500,
          keepShow: true,
          background: '#c1c1c1',
          opacity: 1
        }
      },
      nowDate: '',
      bookingInfo: {
        type: Object,
        default: null
      },
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPER RACK', cd: 'SR', rmk: '40' }
      ],
      agreeYn: 'N',
      picNmh: '',
      // Description of Goods
      description: '',
      // Invoice Model
      invoiceModel: '',
      toolTipActive: false,
      cstInfoList: [],
      cntrList: [],
      selectFunc: null,
      customComponent: null,
      popParams: {},
      uploadParam: [],
      formData: {
        entPicNo: '',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        //3rd Party Shipper
        updtShprCstNm: '',
        reqRsnCont: '',
        cneCstNm: '',
        uploadFileInfos: [],
        blNo: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$ekmtcCommon.isNotEmpty(this.$props.parentInfo.blNo)) {
        this.setDelegateYn(this.$props.parentInfo.blNo)
      }
    }
  },
  created () {
    const parentInfo = this.parentInfo
    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNo)) {
      this.init()
    } else {
      const TH = this
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), function () {
        TH.$emit('close')
      })
    }
  },
  methods: {
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo

      await this.setDelegateYn(parentInfo.blNo)

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
        } else {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      }

      const arrPromise = [
        commons.getServerTime().then(res => {
          if (res.data !== undefined && res.data !== null) {
            TH.nowDate = res.data.substring(0, 8)
          }
        }),
        commons.getCstInfo().then(res => {
          this.cstInfoList = res
        })
      ]

      arrPromise.push(
        docs.getReqDocBookingBl(parentInfo.blNo, parentInfo.bkgNo, parentInfo.type).then(async res => {
          const result = res.data
          await TH.setBookingInfo(result)
        })
      )

      await Promise.all(arrPromise)
    },
    async setDelegateYn (blNo) {
      // CD_ID = 01007 (30: 수출 ALL, 34: 제 3국 요청)
      await transCommon.getBlDelegateYn({ blNo: blNo, arrCstGrpCatCd: ['30', '34'] }).then(async (res) => {
        if (res.data === 'Y') {
          this.delegateYn = 'Y'
        } else {
          this.delegateYn = 'N'
        }
      })
    },
    async setBookingInfo (result) {
      this.bookingInfo = result

      if (this.$ekmtcCommon.isNotEmpty(this.bookingInfo.blNo)) {
        const blNo = this.bookingInfo.blNo
        await docs.getBkgContainerInfo(blNo).then(res => {
          console.log(res)
        })
      }
    },
    checkValidationInputAll () {
      let isOk = true
      const prefix = ['updtShprCstNm']

      prefix.forEach(el => {
        if (!this.checkValidationInput(el)) {
          isOk = false
        }
      })

      return isOk
    },
    checkValidationInput (id) {
      let isOk = true
      const frm = document.querySelector('#frm_req_pop')
      const selector = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      return isOk
    },
    async reqSave () {
      let isOk = true
      const TH = this
      const formData = TH.formData

      if (TH.delegateYn === 'Y') {
        if (!TH.checkValidationInputAll()) {
          isOk = false
        }
      }

      if (TH.delegateYn === 'N') {
        formData.uploadFileInfos = this.uploadParam.map((item) => {
          if (item.new !== true) {
            item.fileId = item.asFileId
          }
          return item
        })

        TH.$delete(formData, 'containerList')

        if (TH.delegateYn === 'N' && formData.uploadFileInfos.length === 0) {
          const attachSelector = document.querySelector('#file_att')
          TH.$ekmtcCommon.showErrorTooltip(attachSelector, TH.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      if (isOk) {
        if (TH.agreeYn !== 'Y' && TH.delegateYn === 'Y') {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050P190.029'))
          return
        }

        let successFlag = true

        formData.vslCd = TH.bookingInfo.vslCd
        formData.voyNo = TH.bookingInfo.voyNo
        formData.polCtrCd = TH.bookingInfo.polCtrCd
        formData.polPortCd = TH.bookingInfo.polPortCd
        formData.podCtrCd = TH.bookingInfo.podCtrCd
        formData.podPortCd = TH.bookingInfo.podPortCd
        formData.shprCstNo = TH.bookingInfo.shprCstNo
        formData.shprCstNm = TH.bookingInfo.shprCstNm
        formData.blNo = TH.parentInfo.blNo
        formData.bkgNo = TH.bookingInfo.bkgNo
        //bl에서 등록 할 경우 06, bkg_no 는 22
        formData.reqCatCd = TH.parentInfo.reqCatCd
        if (TH.$ekmtcCommon.isEmpty(formData.reqCatCd)) formData.reqCatCd = '06'

        if (this.delegateYn === 'Y') {
          const frm = document.querySelector('#frm_req_pop')
          const ele = frm.querySelector('.cod_cont_area')
          this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

          await this.$ekmtcCommon.screenToPdf({ cssSelector: '.cod_cont_area' })
            .then(async (res) => {
              const reqData = new FormData()
              const fileName = this.$t('msg.ONEX050P190.001') + '.pdf'
              reqData.append('files', res.output('blob'), fileName)

              await commons.tempFileUpload(reqData).then((result) => {
                  const fileId = result.data.fileId

                  const fileObj = {
                    fileId: fileId,
                    originalName: fileName
                  }

                  formData.uploadFileInfos.push(fileObj)
                })
                .catch(err => {
                  console.log(err)
                })

                TH.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
            })
        }

        let result = null
        await docs.reqDocSubmit(formData).then(res => {
          if (res.headers.respcode !== 'C0000') {
            successFlag = false
          }

          result = res.data
        }).catch(() => {
          successFlag = false
        }).finally(() => {
          if (successFlag && result !== null) {
            TH.$ekmtcCommon.alertCallback(TH.$t('js.common.008'), function () {
              TH.$emit('selectFunc', result)
              TH.$emit('close')
            })
          } else {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.CSBK100.237'))
          }
        })
      }
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    guidePop () {
      this.openPopup('ContainerGuidePop')
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    closePopup (compNm) {
      const elemGuidPopup = document.querySelector('#guide_pop')
      this.customComponent = null
      this.selectFunc = null

      if (compNm !== 'confirmClose') {
        elemGuidPopup.style.display = 'none'
      } else {
        this.$emit('close')
      }

      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    },
    removeUploadFile () {
      this.uploadParam.splice(0, 1)
      this.customComponent = null
    },
    showToolTip () {
      console.log(this.toolTipActive)
      this.toolTipActive = !this.toolTipActive
    },
    async moveDelegateReqPage () {
      const TH = this
      // 위수임 신청 페이지로 이동하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.089'), useConfirmBtn: true })) {
        TH.$emit('close')
        TH.$router.push({ name: 'import-business-agency-information-main' })
      }
    },
    async confirmClose () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX020P130.062'), useConfirmBtn: true })) {
        this.$emit('close')
      }
    },
    async excelDownload (fileName) {
      console.log('#### excel Download ####')

      const data = {
        fileNm: fileName,
        blNo: this.parentInfo.blNo
      }

      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.blNo)) {
        //using blob download
        const result = await docs.getExcelDownload(data)
        console.log('##### excelDownload #####')
        console.log(result)
        //blob 객체 생성
        const blob = new Blob([result.data], { type: result.headers['content-type'] })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //IE에서 동작
          window.navigator.msSaveOrOpenBlob(result.data, fileName)
        } else {
          console.log(result.data)
          //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = url
          link.target = '_self'
          link.download = fileName
          link.click()

          //url 사용 후 메모리에서 제거
          window.URL.revokeObjectURL(url)
        }
      }
    }
  }
}
</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
